<template>
  <div>
    <associatedOption
      :fieldAttributes="{
        name: fieldAttributes.name,
        options: evalOptions,
        ...fieldAttributes,
      }"
      :value="value"
      type="select"
      @input="updateSingleValue"
    ></associatedOption>
  </div>
</template>
<script>
import associatedOption from "@/commonComponents/associatedOption.vue";
import options from "@/cfg/options.json";

export default {
  components: {
    associatedOption,
  },
  props: ["result", "fieldAttributes", "value"],
  data() {
    return {
      evalOptions: options.evalTypes,
    };
  },
  methods: {
    updateSingleValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>